import React from 'react'

const Video = props => {
  return (
    <div className="video-wrapper">
      <iframe
        width="100%"
        title={props.title ? props.title : ''}
        src={props.src}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default Video
