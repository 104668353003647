import React from 'react'
import { css } from 'emotion'
import Button from './global/button'
import config from '../siteConfig'

const backgroundStyle = css({
  background: config.theme.colors.offwhite,
  color: config.theme.colors.medGrey,
  padding: '2em',
  maxWidth: '400px',
  textAlign: 'center',
  margin: '-110px auto 50px',
  boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)',
})

const headerStyle = css({
  margin: '0 0 10px 0',
  lineHeight: '1.4em',
})

const pStyle = css({
  fontSize: '0.8rem !important',
  margin: '0 0 20px 0',
  lineHeight: '1.4em',
})

const NewsletterSignUp = props => {
  return (
    <div className={backgroundStyle}>
      <p className={headerStyle}>Free Newsletter</p>
      <p className={pStyle}>
        For troubleshooting tips and information on our electrical training programs
      </p>
      <Button
        type="secondary"
        text="Sign Up"
        href="https://visitor.r20.constantcontact.com/manage/optin?v=001Re2XAHrK4vlFTRaxxp_JhdFSCGwu-S1pEYKOLZEwrtaMpBVDAeq-THxG1RWiVwEpEk-yISZpMOQ%3D"
        target="_blank"
      />
    </div>
  )
}

export default NewsletterSignUp
