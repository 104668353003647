import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { css } from 'emotion'
import mq from '../utils/index'
import config from '../siteConfig'
import { Row, Col, Icon } from '../components/global'
import Image from '../components/image'

const headerStyle = css({
  background: config.theme.colors.primary,
})

const h1Style = css(
  mq({
    margin: 0,
    fontSize: ['0.8rem', '0.8rem', '1rem', '1rem'],
  })
)

const h1aStyle = css({
  textDecoration: 'none',
  color: 'white',
})

const logoContainer = css({
  display: 'flex',
  alignItems: 'center',
})

const Header = props => (
  <header className={`main-nav-header ${headerStyle}`}>
    <div className={`container container-thin`}>
      <Row alignItems="center" break={false}>
        <Col width="75%" align="left">
          <div className={logoContainer}>
            <Image file="logo-sm.png" />
            <h1 className={h1Style}>
              <Link to="/" className={h1aStyle}>
                {props.siteTitle}
              </Link>
            </h1>
          </div>
        </Col>
        <Col width="25%" align="right">
          <button className="btn-no-style" onClick={props.onToggleVisible}>
            <Icon icon="hamburger" />
          </button>
        </Col>
      </Row>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
