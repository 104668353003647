import React from 'react'
import { Link } from 'gatsby'
import { css } from 'emotion'
import { Row, Col, Icon } from './global'
import config from '../siteConfig'

const Nav = props => {
  const pad = css({
    padding: '0.8em',
    position: 'fixed',
    zIndex: '1000',
    width: '100%',
    display: 'none',
    top: '0',
    bottom: '0',
    background: 'rgba(0,0,0,0.25)',
  })

  const style = css({
    background: 'white',
    padding: '1em',
    boxShadow: config.theme.shadows.default,
    position: 'relative',
    margin: 'auto',
    maxWidth: '660px',
  })

  const icon = css({
    position: 'absolute',
    right: '1em',
    top: '1em',
  })

  const titleStyles = css({
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    color: config.theme.colors.grey,
    marginBottom: '1em',
  })

  return (
    <div
      role="navigation"
      aria-label="Main"
      className={`popover-nav ${pad} ${props.isVisible ? 'visible' : ''}`}
    >
      <div className={`${style} navigation`}>
        <button onClick={props.onToggleVisible} className={`${icon} btn-no-style`}>
          <Icon icon="close" />
        </button>
        <ul className={`navigation-primary`}>
          <li>
            <strong>
              <Link to="/technicians">For Technicians</Link>
            </strong>
          </li>
          <li>
            <strong>
              <Link to="/shop-owners">For Shop Owners</Link>
            </strong>
          </li>
          <li>
            <strong>
              <Link to="/teachers">For Teachers</Link>
            </strong>
          </li>
        </ul>
        <hr />
        <p className={titleStyles}>Electrical Training</p>
        <Row break={false}>
          <Col align="left">
            <ul>
              <li>
                <Link to="/products/advanced-electronics-training">
                  Advanced Electronics Training
                </Link>
              </li>
              <li>
                <Link to="/products/circuit-board-trainer-inventory-clearance">
                  Circuit Board Trainer Inventory Clearance
                </Link>
              </li>
              <li>
                <Link to="/all-training">All Training</Link>
              </li>
            </ul>
          </Col>
          <Col align="left">
            <ul>
              <li>
                <Link to="/products/first-things-first">
                  <em>FIRST THINGS FIRST</em>
                </Link>
              </li>
              <li>
                <Link to="/products/60-lessons">The 60 Lesson Course</Link>
              </li>
              <li>
                <Link to="/products/shortcuts">
                  Electrical <em>SHORTCUTS</em>
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
        <hr />
        <p className={titleStyles}>About</p>
        <Row break={false}>
          <Col align="left">
            <ul>
              <li>
                <Link to="/about">About Veejer</Link>
              </li>
              <li>
                <Link to="/videos">Videos</Link>
              </li>
              <li>
                <Link to="/webinars">Webinars</Link>
              </li>
            </ul>
          </Col>
          <Col align="left">
            <ul>
              <li>
                <Link to="/vet-honor-roll">V.E.T Honor Roll</Link>
              </li>
              <li>
                <Link to="/testimonials">Testimonials</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Nav

/*<li>
  <Link to="/testimonials">Testimonials</Link>
</li>*/
