import React from 'react'
import Button from './button'
import PropTypes from 'prop-types'

const ProductBookCard = props => {
  const animate = props.animate === undefined ? true : props.animate
  const titleSmall = props.titleSmall === undefined ? false : true
  return (
    <div
      className={
        animate
          ? `product-book-card flip-container fade-in-from-bottom`
          : `product-book-card flip-container`
      }
      itemScope
      itemType="http://schema.org/Product"
      onTouchStart={() => null}
    >
      <div className={animate ? `flipper` : ``}>
        <div className="front product-book-card-inner">
          <p
            className={titleSmall ? `title small` : `title`}
            itemProp="name"
            dangerouslySetInnerHTML={{ __html: props.title }}
          />
          {props.step ? <span>{props.step}</span> : null}
          <div itemProp="offers" itemType="http://schema.org/Offer" itemScope>
            <link itemProp="url" href={props.productLink} />
            <meta itemProp="availability" content="https://schema.org/InStock" />
            <meta itemProp="priceCurrency" content="USD" />
            <meta itemProp="itemCondition" content="https://schema.org/NewCondition" />
            <meta itemProp="price" content={props.price} />
            <div itemProp="seller" itemType="http://schema.org/Organization" itemScope>
              <meta itemProp="name" content="Veejer Enterprises, Inc." />
            </div>
          </div>
        </div>
        {animate ? (
          <div className="back product-book-card-inner">
            <p dangerouslySetInnerHTML={{ __html: props.back }} />
            <p>
              <Button href={props.productLink} text="Learn More" />
            </p>
          </div>
        ) : null}
      </div>
    </div>
  )
}

ProductBookCard.propTypes = {
  title: PropTypes.string.isRequired,
  productLink: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  animate: PropTypes.bool,
  titleSmall: PropTypes.bool,
}

export default ProductBookCard
