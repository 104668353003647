import React from 'react'
import { css } from 'emotion'
import mq from '../../../utils/index'

const Row = props => {
  let rowStyle = {
    display: ['block', 'block', 'flex', 'flex'],
    justifyContent: 'space-between',
  }

  if (props.break === false) {
    rowStyle.display = 'flex'
  }

  if (undefined !== props.justifyContent) {
    rowStyle.justifyContent = props.justifyContent
  }

  rowStyle.alignItems = props.alignItems || 'stretch'

  const style = css(mq(rowStyle))

  return <div className={style}>{props.children}</div>
}

export default Row
