import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'emotion'
import config from '../../siteConfig'

const primaryButtonStyle = css({
  color: config.theme.colors.primary,
  boxShadow: config.theme.shadows.default,
  background: 'white',
})

const secondaryButtonStyle = css({
  color: 'white',
  boxShadow: config.theme.shadows.default,
  background: config.theme.colors.primary,
})

const flatButtonStyle = css({
  color: config.theme.colors.offwhite,
})

const flatButtonPrimary = css({
  color: config.theme.colors.primary,
})

const Button = props => {
  let target = props.target || ''
  let type = ''
  switch (props.type) {
    case 'secondary':
      type = secondaryButtonStyle
      break
    case 'flatPrimary':
      type = flatButtonPrimary
      break
    case 'flat':
      type = flatButtonStyle
      break
    default:
      type = primaryButtonStyle
  }
  return (
    <a
      className={`button ${type}`}
      href={props.href}
      target={target}
      rel="noopener"
      dangerouslySetInnerHTML={{ __html: props.text }}
    />
  )
}

Button.propTypes = {
  type: PropTypes.string,
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
  text: PropTypes.string.isRequired,
}

export default Button
