const config = {
  theme: {
    colors: {
      primary: '#990000',
      secondary: '#1F1F81',
      red: '#B81414',
      maroon: '#880000',
      grey: '#999999',
      medGrey: '#666666',
      darkGrey: '#333333',
      offwhite: '#F2F2F2',
    },
    shadows: {
      default: '2px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
  breakPoints: [576, 768, 992, 1200],
  products: {
    buyLinks: {
      phaseOne:
        'http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=4965dcf1e0d84c4fafcc934b783a3509',
      h111a: 'http://www.1shoppingcart.com/app/netcart.asp?MerchantID=177&ProductID=122147',
      h113: 'http://www.1shoppingcart.com/app/netcart.asp?MerchantID=177&ProductID=936145',
      h115:
        'http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=a6e1d24fb5ede2cedd4d631efd5ba43c',
      h116:
        'http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=23a3f6c5e884ec6c2f80921acd1f50b4',
      h200:
        'http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=e9c253a179544b7ba98fed917c4e972e',
      w001: '',
    },
  },
}

export default config
