import React from 'react'
import PropTypes from 'prop-types'
import Technicians from '../../images/icons/technicians.svg'
import ShopOwners from '../../images/icons/shop-owners.svg'
import Teachers from '../../images/icons/teachers.svg'
import Hamburger from '../../images/icons/hamburger.svg'
import Close from '../../images/icons/close.svg'
import Youtube from '../../images/icons/youtube.svg'
import Webinar from '../../images/icons/webinar.svg'
import Article from '../../images/icons/article.svg'
import PDF from '../../images/icons/pdf.svg'

const Icon = props => {
  switch (props.icon) {
    case 'shop-owners':
      return <ShopOwners />
    case 'teachers':
      return <Teachers />
    case 'technicians':
      return <Technicians />
    case 'close':
      return <Close />
    case 'youtube':
      return <Youtube />
    case 'webinar':
      return <Webinar />
    case 'article':
      return <Article />
    case 'pdf':
      return <PDF />
    default:
      return <Hamburger />
  }
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
}

export default Icon
