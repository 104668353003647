import React from 'react'
import { css } from 'emotion'
import { Link } from 'gatsby'
import { Row, Col } from './global/grid'
import Newsletter from '../components/newsletterSignUp'
import Icon from '../components/global/icon'

const copyRightStyles = css({
  textAlign: 'center',
  margin: '1rem 0 0',
  fontSize: '0.8rem !important',
})

const Footer = props => {
  return (
    <div className="container">
      <Newsletter />
      <Row>
        <Col align="left" flexGrow={true}>
          <p className="title">Who We Help</p>
          <ul>
            <li>
              <Link to="/technicians">Technicians</Link>
            </li>
            <li>
              <Link to="/shop-owners">Shop Owners</Link>
            </li>
            <li>
              <Link to="/teachers">Teachers</Link>
            </li>
          </ul>
        </Col>
        <Col align="left" flexGrow={true}>
          <p className="title">Training</p>
          <Row>
            <Col align="left">
              <ul>
                <li>
                  <Link to="/products/shortcuts">
                    Electrical <em>SHORTCUTS</em>
                  </Link>
                </li>
                <li>
                  <Link to="/products/circuit-board-trainer-inventory-clearance">
                    Circuit Board Trainer Inventory Clearance
                  </Link>
                </li>
                <li>
                  <Link to="/all-training">All Training</Link>
                </li>
              </ul>
            </Col>
            <Col align="left">
              <ul>
                <li>
                  <Link to="/products/first-things-first">
                    <em>FIRST THINGS FIRST</em>
                  </Link>
                </li>
                <li>
                  <Link to="/products/60-lessons">The 60 Lesson Course</Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
        <Col align="left" flexGrow={true}>
          <p className="title">About</p>
          <Row>
            <Col align="left">
              <ul>
                <li>
                  <Link to="/about">About Veejer</Link>
                </li>
                <li>
                  <Link to="/videos">Videos</Link>
                </li>
                <li>
                  <Link to="/webinars">Webinars</Link>
                </li>
              </ul>
            </Col>
            <Col align="left">
              <ul>
                <li>
                  <Link to="/vet-honor-roll">V.E.T Honor Roll</Link>
                </li>
                <li>
                  <Link to="/testimonials">Testimonials</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <p className="text-center">
        <a
          href="https://www.youtube.com/channel/UCkMR-nqnMjax4eoBF8UcFRg"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon="youtube" />
        </a>
      </p>
      <p className={copyRightStyles}>
        © {new Date().getFullYear()}, Veejer Enterprises, Inc. All Rights Reserved.
      </p>
    </div>
  )
}

export default Footer

/*<li>
  <Link to="/testimonials">Testimonials</Link>
</li>*/
