import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from 'emotion'
import Header from './header'
import Footer from './footer'
import Nav from './nav'
import config from '../siteConfig'
import './layout.css'

const footerStyle = css({
  background: config.theme.colors.grey,
})

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [isVisible, setIsVisible] = useState(false)

  const toggleVisible = () => {
    setIsVisible(!isVisible)
  }

  return (
    <>
      <Header onToggleVisible={toggleVisible} siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <footer className={`${footerStyle} footer`}>
        <Footer />
      </footer>
      <Nav isVisible={isVisible} onToggleVisible={toggleVisible} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  includeNotice: PropTypes.bool,
}

export default Layout
