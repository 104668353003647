import React from 'react'
import { css } from 'emotion'
import mq from '../../../utils/index'

const Col = props => {
  let colStyle = {
    flex: '1',
  }

  if (props.flexGrow) {
    colStyle = {
      flexGrow: '1',
    }
  }

  if (props.width) {
    colStyle = {
      width: ['100%', '100%', props.width || 'auto', props.width || 'auto'],
    }
  }

  colStyle.textAlign = 'center'

  if (props.align === 'left') {
    colStyle.textAlign = 'left'
  }

  if (props.align === 'right') {
    colStyle.textAlign = 'right'
  }

  const style = css(mq(colStyle))

  return <div className={style}>{props.children}</div>
}

export default Col
