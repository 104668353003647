import React from 'react'
import { css } from 'emotion'

const closeOutStyles = css({
  margin: '0.5em 0',
  textAlign: 'center',
  lineHeight: '1',
  fontSize: '1.8rem',
})

const DownloadPhase1PDF = props => {
  const inverse = props.inverse ? 'inverse-link' : ''
  return (
    <p className={closeOutStyles}>
      <a
        className={inverse}
        target="_blank"
        rel="noopener noreferrer"
        href="/Ultimate_Electrical_Study_Guide_SP.pdf"
      >
        Download a free copy of our Ultimate Electrical Study Guide
      </a>
    </p>
  )
}

export default DownloadPhase1PDF
