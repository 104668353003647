import React from 'react'
import { css } from 'emotion'
import mq from '../../utils/mediaQuery'
import config from '../../siteConfig'

const labelStyle = css(
  mq({
    display: 'inline-block',
    background: config.theme.colors.offwhite,
    boxShadow: config.theme.shadows.default,
  })
)

const Label = ({ children }) => {
  return <div className={`${labelStyle} label`}>{children}</div>
}

export default Label
