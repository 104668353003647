import React from 'react'
import { Link } from 'gatsby'

const WhatNext = props => {
  const kits = props.kits || false
  return (
    <>
      <h2>What Next?</h2>
      {!kits ? (
        <>
          <p>
            The 60 Lesson Vehicle Electronics Training Course is an in-depth instruction in vehicle
            electrical-electronic circuits and troubleshooting. This program is ideal as a back-up
            to our hands-on training programs.
          </p>
          <p className="boost">
            The 60 Lesson Vehicle Electronics Training Course is now digitally{' '}
            <a href="https://training.veejer.com/?utm_source=veejer.com&utm_medium=website&utm_campaign=what-next">
              available on-line here
            </a>{' '}
            or purchase a <Link to="/products/60-lessons">print version here</Link>.
          </p>
          <p className="demote">
            All 60 lessons have been translated and are available in Spanish!
          </p>
        </>
      ) : (
        <>
          <p>
            We’ve specially designed 5 circuit boards for guided hands-on repetition that develops
            electrical troubleshooting skills and maintains interest to keep the student involved.{' '}
            <Link to="/products/circuit-board-trainer-inventory-clearance">See all kits here</Link>.
          </p>
          <ol>
            <li>
              <Link to="/products/h-111a">Starter Kit H-111A</Link>
            </li>
            <li>
              <Link to="/products/h-113">H-113 Troubleshooting DC Motor Circuits</Link>
            </li>
            <li>
              <Link to="/products/h-115">H-115, Relay Circuit Troubleshooting</Link>
            </li>
            <li>
              <Link to="/products/h-116">Wire Harness Troubleshooting Trainer H-116</Link>
            </li>
            <li>
              <Link to="/products/h-200">CAN Bus Troubleshooting Trainer H-200</Link>
            </li>
          </ol>
        </>
      )}
    </>
  )
}

export default WhatNext
