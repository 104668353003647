import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'emotion'
import mq from '../../utils/mediaQuery'
import Button from '../global/button'
import config from '../../siteConfig'

const ProductCard = props => {
  const cardStyles = css(
    mq({
      background:
        props.type === 'secondary' ? config.theme.colors.offwhite : config.theme.colors.primary,
      marginBottom: ['1em', '1em', '1em', '1em'],
    })
  )

  const titleStyles = css({
    background: props.type === 'secondary' ? config.theme.colors.grey : config.theme.colors.maroon,
    padding: '1em',
    color: 'white',
    textAlign: 'center',
    margin: '0',
  })

  const cardContentStyles = css({
    padding: '1em',
    color: 'white',
  })

  const descriptionStyles = css({
    fontSize: '0.8rem',
    color: props.type === 'secondary' ? config.theme.colors.darkGrey : 'white',
    lineHeight: 1.4,
  })

  return (
    <div className={cardStyles}>
      <h4 className={titleStyles}>{props.title}</h4>
      <div className={cardContentStyles}>
        <p className={descriptionStyles}>{props.description}</p>
        <Button href={props.url} text={`Learn More`}></Button>
      </div>
    </div>
  )
}

ProductCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default ProductCard
